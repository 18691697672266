import { createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom";
import vid from "../../assets/images/HOST IMAGE/pic01 (1).jpg";
import vid1 from "../../assets/images/HOST IMAGE/pic02 (2).jpg";
import vid2 from "../../assets/images/HOST IMAGE/pic03 (3).jpg";
import vid3 from "../../assets/images/HOST IMAGE/pic03 (4).jpg";
import vid4 from "../../assets/images/HOST IMAGE/pic02 (5).jpg";
import vid5 from "../../assets/images/HOST IMAGE/pic01 (6).jpg";
import vid6 from "../../assets/images/HOST IMAGE/pic02 (7).jpg";
import vid7 from "../../assets/images/HOST IMAGE/pic03 (8).jpg";
import vid8 from "../../assets/images/HOST IMAGE/pic01 (9).jpg";
import noAva from "../../assets/images/noAva.png";
import * as cts from "../../constants/ui/svg";
import i18n from "locales/i18n";
import { useCallback, useEffect, useRef, useState } from "react";
import BreadcrumbComponent from "components/Common/Breadcrumb";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchChannels } from "slices/channelSlice";
import { URL_UPLOAD_IMAGE } from "../../constants";
const DeliveryPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const channelsSt = useAppSelector((state) => state.channels.channels);

  useEffect(() => {
    dispatch(fetchChannels({ channelName: "general" }));
  }, []);

  return (
    <div className="flex pt-[10px] pb-[40px] mt-[2px] delivery_video justify-center">
      <div className="flex-col size_content_hd flex w-full">
        <div className="">
          <BreadcrumbComponent breadMain={i18n.t("DURING_DELIVERY")} />
        </div>
        <span className="text-[#101828] mt-[10px] text-[24px] notosans_bold  max-500px:text-lg max-500px:font-bold">
          {i18n.t("DURING_DELIVERY")}
        </span>
        <div className="grid grid-cols-4 gap-4 mt-[20px] mb-[40px] max-800px:grid-cols-3 max-500px:mt-3 max-500px:grid-cols-2 max-500px:gap-3">
          {channelsSt?.map((x: any, y: number) => {
            return (
              <div
                key={y}
                className="flex flex-col cursor-pointer hover:shadow-[0px_4px_8px_-2px_rgba(0,0,0,0.4)] rounded-[8px] max-500px:w-[45vw] max-500px:h-[45vw]"
              >
                <>
                  <div
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate({
                        pathname: "/livestream",
                        search: createSearchParams({
                          key: `${x?.user?._id}`,
                        }).toString(),
                      });
                    }}
                    className="relative h-[200px] max-500px:h-full max-500px:relative"
                  >
                    <div className="flex items-center justify-between mt-[8px] mx-[8px] absolute w-11/12">
                      <span>{cts.live1Icon}</span>
                      <div className="bg-[#182230] rounded-[4px] flex items-center justify-center h-[20px] w-[53px] max-500px:bg-[#FCFCFDB2]">
                        <span className="sz12_view">{cts.view1Icon}</span>
                        <span className="text-[#FFFFFF] text-[12px] notosans_normal ml-[5px] max-500px:text-[#101828]">
                          2023
                        </span>
                      </div>
                    </div>
                    <img
                      className="w-full rounded-t-[8px] object-cover max-500px:rounded-lg h-full"
                      src={
                        x?.user?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${x?.user?.avatar}` : noAva
                      }
                    />

                    <div className=" hidden p-[12px] max-500px:flex flex-col  rounded-b-[8px] absolute left-0 right-0 bottom-0  bg_linear ">
                      <span className="text-[#101828] text-[14px] notosans_bold text_detail  max-500px:text-white max-500px:line-clamp-1">
                        動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。
                      </span>
                      <div
                        onClick={() => {
                          window.scrollTo(0, 0);

                          navigate({
                            pathname: "/streamer-profile",
                            search: createSearchParams({
                              key: `${x?.user?._id}`,
                            }).toString(),
                          });
                        }}
                        className="flex items-center mt-[12px] cursor-pointer max-500px:mt-1"
                      >
                        <img
                          className="h-[30px] w-[30px] rounded-[50%] object-cover max-500px:h-4 max-500px:w-4"
                          src={
                            x?.user?.avatar
                              ? `${URL_UPLOAD_IMAGE}/avatars/${x?.user?.avatar}`
                              : noAva
                          }
                        />
                        <span className="text-[#101828] text-[12px] notosans_normal ml-[8px]  max-500px:text-white">
                          {x?.user?.nickName}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-[12px] flex flex-col bg-[#FCFCFD] rounded-b-[8px] max-500px:hidden">
                    <span className="text-[#101828] text-[14px] notosans_bold text_detail  max-500px:text-white">
                      動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。
                    </span>
                    <div
                      onClick={() => {
                        window.scrollTo(0, 0);

                        navigate({
                          pathname: "/streamer-profile",
                          search: createSearchParams({
                            key: `${x?.user?._id}`,
                          }).toString(),
                        });
                      }}
                      className="flex items-center mt-[12px] cursor-pointer"
                    >
                      <img
                        className="h-[30px] w-[30px] rounded-[50%] object-cover"
                        src={
                          x?.user?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${x?.user?.avatar}` : noAva
                        }
                      />
                      <span className="text-[#101828] text-[12px] notosans_normal ml-[8px]  max-500px:text-white">
                        {x?.user?.nickName}
                      </span>
                    </div>
                  </div>
                  {/* <div>
                    <span className="text-[#101828] text-[14px] notosans_bold text_detail">
                      動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。
                    </span>
                     
                </div> */}
                </>
              </div>
            );
          })}
        </div>
        {/* <div className="flex items-center justify-center cursor-pointer">
        <span className="text-center text-[13px] text-[#101828] notosans_medium mr-[5px]">
          {i18n.t('SHOW_MORE')}
        </span>
        <span>{cts.showMore}</span>
      </div> */}
      </div>
    </div>
  );
};
export default DeliveryPage;
