import vid1 from "../../assets/images/vid1.png";
import vid2 from "../../assets/images/vid2.png";
import { useEffect, useState } from "react";
import * as cts from "../../constants/ui/svg";
import i18n from "locales/i18n";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import TabHome from "./component/home";
import TabProfile from "./component/profile";
import TabImage from "./component/image";
import BreadcrumbComponent from "components/Common/Breadcrumb";
import { Button, Tabs } from "antd";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import { useDispatch } from "react-redux";
import { getReport } from "slices/userOfShopSlice";
import moment from "moment";
import { URL_UPLOAD_IMAGE } from "../../constants";
import ButtonFollowComponent from "components/Common/ButtonFollow";
import { getGallery } from "slices/galleryStreamerSlice";
import noAva from "../../assets/images/noAva.png";
import { formatNumberNew } from "helpers";
import { getToken } from "helpers/Token";
import { getDetailHost, getFollow } from "slices/followSlice";

const StreamerProfile = () => {
  const location = useLocation();
  const { beforePath, prevPath } = location.state || {};
  const [searchparams] = useSearchParams();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(1);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const idHost = searchparams.get("key")?.toString() || "";
  const detailHost = useAppSelector((state) => state.follow.detailHost);
  const channelsSt = useAppSelector((state) => state.channels.channels);
  const reportChannel = useAppSelector((state) => state.userOfShop.reportChannel);
  const userInfo = useAppSelector((state) => state.users.userInfo);
  const listHost = useAppSelector((state) => state.follow.listHost);
  const url = window.location.href;
  const token = getToken() as string;
  const arrDeliveries = [
    {
      id: 1,
      img: vid1,
      time: "1:30:00",
      title: "動画のタイトル動画のタイトル動画のタイトル動画のタイトル動画のタイトル",
      view: 2023,
      day: "2023/01/01",
    },
    {
      id: 2,
      img: vid2,
      time: "1:30:00",
      title: "動画のタイトル動画のタイトル動画のタイトル動画のタイトル動画のタイトル",
      view: 2023,
      day: "2023/01/01",
    },
    {
      id: 1,
      img: vid1,
      time: "1:30:00",
      title: "動画のタイトル動画のタイトル動画のタイトル動画のタイトル動画のタイトル",
      view: 2023,
      day: "2023/01/01",
    },
    {
      id: 2,
      img: vid2,
      time: "1:30:00",
      title: "動画のタイトル動画のタイトル動画のタイトル動画のタイトル動画のタイトル",
      view: 2023,
      day: "2023/01/01",
    },
    {
      id: 1,
      img: vid1,
      time: "1:30:00",
      title: "動画のタイトル動画のタイトル動画のタイトル動画のタイトル動画のタイトル",
      view: 2023,
      day: "2023/01/01",
    },
    {
      id: 2,
      img: vid2,
      time: "1:30:00",
      title: "動画のタイトル動画のタイトル動画のタイトル動画のタイトル動画のタイトル",
      view: 2023,
      day: "2023/01/01",
    },
  ];
  const countViews = reportChannel?.liveStreamer?.reduce((acc: any, cur: any) => {
    return acc + (cur.countViews || 0);
  }, 0);
  const arrInfo = [
    { icon: cts.channel, ctn: url },
    { icon: cts.videoIcon, ctn: `${reportChannel?.liveStreamer?.length || 0}${i18n.t("VIDEO")}` },
    { icon: cts.subIcon, ctn: `${detailHost?.follower?.length || 0} ${i18n.t("FOLLOWER")}` },
    { icon: cts.view1Icon, ctn: `${countViews || 0}${i18n.t("VIEWED_TIMES")}` },
    {
      icon: cts.timeIcon,
      ctn: `${moment(detailHost?.createdAt).format("YYYY/MM/DD")}${i18n.t("PARTICIPATED_FROM")}`,
    },
    { icon: cts.location1Icon, ctn: detailHost?.address },
  ];
  const arrTab = [
    // {
    //   name: i18n.t("HOME"),
    //   children: <TabHome width={width} arrDeliveries={arrDeliveries} arrInfo={arrInfo} />,
    // },
    { name: i18n.t("HOME"), children: <TabProfile arrInfo={arrInfo} /> },
    // { name: i18n.t("VIDEO"), children: <TabVideo arrDeliveries={arrDeliveries} /> },
    { name: i18n.t("IMAGE"), children: <TabImage /> },
  ];
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    if (userInfo._id && listHost?.length == 0) {
      dispatch(getFollow({ follower: userInfo._id, type: "get_all" }));
    }
  }, [userInfo._id]);
  useEffect(() => {
    if (idHost) {
      dispatch(getReport({ user: idHost, type: "month" }));
    }
  }, [idHost]);
  useEffect(() => {
    if (idHost) {
      dispatch(getDetailHost(idHost as string));
    }
  }, [idHost]);
  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  }, []);
  useEffect(() => {
    dispatch(getGallery({ user: idHost }));
  }, []);
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <div className="flex justify-center w-full bg-white border-t border-[#101828]/10">
      {/* <img className="w-full h-[300px]" src={bg} /> */}
      <div className="flex flex-col w-full size_content_hd">
        <div className=" pt-[10px]">
          {prevPath !== undefined && beforePath !== undefined ? (
            <BreadcrumbComponent
              breadMain={beforePath}
              breadChild={detailHost?.nickName}
              breadBefore={prevPath}
            />
          ) : prevPath !== undefined && beforePath === undefined ? (
            <BreadcrumbComponent breadMain={prevPath} breadChild={detailHost?.nickName} />
          ) : (
            <BreadcrumbComponent breadMain={detailHost?.nickName} />
          )}
        </div>
        <div className=" flex flex-col justify-center items-center gap-3 max-500px:gap-2 max-500px:px-0 max-500px:pt-3">
          <img
            className="h-[120px] w-[120px] rounded-[50%]  img_ava object-cover"
            src={detailHost?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${detailHost?.avatar}` : noAva}
          />
          <span className="flex items-center text-[#101828] text-[24px] notosans_bold">
            {detailHost?.nickName}{" "}
            <span
              className={`${
                !channelsSt?.find((i: any) => i?.user?._id.includes(detailHost?._id))?.user?._id &&
                "hidden"
              } ml-[8px]`}
            >
              {cts.live1Icon}
            </span>
          </span>
          <span className="flex items-center text-[16px] text-[#101828]">
            {/* {detailHost?.email} <span className="mx-[5px]">{cts.dotIcon}</span> */}
            {detailHost?.follower?.length || 0} {i18n.t("FOLLOWER")}
          </span>

          <div className="flex w-full justify-center max-500px:px-4">
            <ButtonFollowComponent idStreamer={idHost} />

            <Button className="bg-white border border-[#EF6820] h-[32px] px-[20px] rounded-[8px] flex items-center justify-center ml-[20px]">
              <span className="icon_share">{cts.shareLinkIcon}</span>
              <span className="text-[14px] text-[#EF6820] notosans_medium ml-[5px]">
                {i18n.t("SHARE")}
              </span>
            </Button>
          </div>
          <div className=" w-full flex border-b border-[#D0D5DD] items-center justify-center max-500px:hidden">
            {arrTab.map((x, y) => {
              return (
                <span
                  key={y}
                  onClick={() => setTab(y + 1)}
                  className={`${
                    tab === y + 1
                      ? "text-[#EF6820] border-[#EF6820] border-b-[2px]"
                      : "text-[#667085] hover:border-[#667085] hover:border-b-[2px]"
                  } text-[18px] h-full flex notosans_normal  font cursor-pointer py-2 w-[132px] justify-center items-center`}
                >
                  {x.name}
                </span>
              );
            })}
          </div>

          <div className="hidden max-500px:flex profile_tabs w-[100vw]">
            <Tabs
              defaultActiveKey="0"
              style={{ width: "100%", overflowX: "scroll", display: "flex" }}
              items={arrTab.map((item, index) => {
                return {
                  label: item.name,
                  key: String(index),
                  children: item.children,
                };
              })}
            />
            {/* {arrLink.map((item) => {
          return (
            <button key={item.id} className="w-[130px]">
              {item.name}
            </button>
          );
        })} */}
          </div>
          {/* <div className="flex justify-between pr-[80px] w-full pb-[20px]">
          <div className="flex flex-col ">
            <span className="flex items-center text-[#101828] text-[24px] notosans_bold">
              HIKARI <span className="ml-[8px]">{cts.live1Icon}</span>
            </span>
            <span className="flex items-center text-[16px] text-[#101828]">
              ＠hikari2023 <span className="mx-[5px]">{cts.dotIcon}</span>2.84M フォロワー
            </span>
          </div>
          <div className="flex">
            <div
              onClick={() => setFollow((prev) => !prev)}
              className={`${
                follow
                  ? "bg-[#EAECF0] text-[#475467] border border-[rgba(0,0,0,0.1)]"
                  : "bg-[#EF6820] text-white"
              }  
            text-[14px] notosans_medium h-[40px] px-[22px] rounded-[8px] 
            shadow-[0px_1px_2px_0px_#1018280D] flex items-center justify-center cursor-pointer`}
            >
              {!follow ? (
                <>
                  <span className="mr-[5px]">{cts.heartIcon}</span>
                  {i18n.t("FOLLOW")}
                </>
              ) : (
                <>{i18n.t("FOLLOWED")}</>
              )}
            </div>
            <Button className="bg-white border border-[#EF6820] h-[40px] px-[20px] rounded-[8px] flex items-center justify-center ml-[20px]">
              <span className="icon_share">{cts.shareLinkIcon}</span>
              <span className="text-[14px] text-[#EF6820] notosans_medium ml-[5px]">
                {i18n.t("SHARE")}
              </span>
            </Button>
          </div>
        </div> */}
        </div>
        <div className="max-500px:hidden">
          {/* {tab === 1 && <TabHome width={width} arrDeliveries={arrDeliveries} arrInfo={arrInfo} />} */}
          {tab === 1 && <TabProfile arrInfo={arrInfo} />}
          {/* {tab === 3 && <TabVideo arrDeliveries={arrDeliveries} />} */}
          {tab === 2 && <TabImage />}
        </div>
      </div>
    </div>
  );
};
export default StreamerProfile;
