import { call, put, takeEvery } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import i18n from "../locales/i18n";

import { errorToast } from "../helpers";

import { showLoading } from "../slices/commonSlice";
import { getLiveStreamAPI } from "../apis";
import * as act from "../slices/liveStreamSlice";
import { LiveStreamState } from "types/liveStream";
import { LiveStreamParams } from "types/liveStream";
import { PayloadAction } from "@reduxjs/toolkit";

function* getLiveStreamSaga(action: PayloadAction<LiveStreamParams>) {
  try {
    const res: AxiosResponse<LiveStreamState> = yield call(getLiveStreamAPI, action.payload);
    if (res.data) {
      yield put(act.getLiveStreamSuccess(res.data));
      yield put(showLoading(false));
    } else {
      errorToast(i18n.t("GET_ERROR"));
      yield put(showLoading(false));
    }
  } catch (error) {
    errorToast(i18n.t("GET_ERROR"));
    yield put(showLoading(""));
  }
}

export default function* liveStreamWatcher() {
  yield takeEvery(act.getLiveStream, getLiveStreamSaga);
}
