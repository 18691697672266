import type { CheckboxProps, MenuProps } from "antd";
import { Button, Checkbox, Dropdown, Form, Input, Modal } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getToken } from "helpers/Token";
import Cookies from "js-cookie";
import i18n from "locales/i18n";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import liveIcon from "../../assets/images/liveIcon.png";
import noAva from "../../assets/images/noAva.png";
import * as cts from "../../constants/ui/svg";
import { getUserInfo, login, registerUser } from "../../slices/userSlice";
// import { showLoading } from "slices/commonSlice";
import ava1 from "../../assets/images/HOST IMAGE/pic01 (3).jpg";
import ava2 from "../../assets/images/HOST IMAGE/pic01 (4).jpg";
import ava3 from "../../assets/images/HOST IMAGE/pic01 (5).jpg";
import ava4 from "../../assets/images/HOST IMAGE/pic01 (6).jpg";
import ava5 from "../../assets/images/HOST IMAGE/pic01 (7).jpg";
import ava from "../../assets/images/HOST IMAGE/pic03 (9).jpg";
import vid2 from "../../assets/images/vid1.png";
import vid1 from "../../assets/images/vid2.png";
import { URL_UPLOAD_IMAGE } from "../../constants";
type FieldType = {
  username?: string;
  email?: string;
  password?: string;
  password_sign_up: string;
};
interface Props {
  setHideSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}
const HeaderComponent: React.FC<Props> = ({ setHideSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector((state) => state.users.userInfo);
  const showLoading = useAppSelector((state) => state.general.loading);
  const [openSearch, setOpenSearch] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [openPopCoin, setOpenPopCoin] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [forgotPass, setForgotPass] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [valueSearch, setValueSearch] = useState("");
  const [arrSearch, setArrSearch] = useState([]);
  const [token, setToken] = useState<any>("");
  const [rememberMe, setRememberMe] = useState(false);
  const pathName = window.location.pathname;
  const cookies_open_login = Cookies.get("open_login");
  const items: MenuProps["items"] = [
    {
      label: (
        <div className="flex items-center">
          <img
            className="rounded-[20px] h-[40px] w-[40px]"
            alt="avatar"
            src={userInfo?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${userInfo?.avatar}` : noAva}
          />
          <div className="flex flex-col ml-[12px]">
            <span className="text-[14px] text-[#344054] notosans_bold cursor-pointer">
              {userInfo?.nickName}
            </span>
            <span className="text-[14px] text-[#475467] notosans_normal cursor-pointer">
              {userInfo?.email}
            </span>
          </div>
        </div>
      ),
      key: "0",
    },

    {
      type: "divider",
    },
    {
      label: (
        <Link onClick={() => window.scrollTo(0, 0)} to="/my-account" className="flex items-center">
          <span>{cts.userAva}</span>
          <span className="text-[14px] notosans_normal cursor-pointer text-[#101828]">
            {i18n.t("MY_ACCOUNT")}{" "}
          </span>
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <div className="flex items-center ">
          <span>{cts.headIcon}</span>
          <span className="text-[14px] notosans_normal cursor-pointer text-[#101828]">
            {i18n.t("HELP")}{" "}
          </span>
        </div>
      ),
      key: "3",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          className="flex items-center"
          onClick={() => {
            Cookies.remove("token_webnomi");
            window.location.href = "/";
          }}
        >
          <span>{cts.logOutIcon}</span>
          <span className="text-[14px] notosans_normal cursor-pointer text-[#101828]">
            {i18n.t("LOG_OUT")}{" "}
          </span>
        </div>
      ),
      key: "5",
    },
  ];
  const arrCoin = [
    { coin: 50, discount: "29%", money: 3000 },
    { coin: 100, money: 3000 },
    { coin: 300, discount: "29%", money: 3000 },
    { coin: 500, discount: "29%", money: 3000 },
    { coin: 1000, money: 3000 },
  ];
  const onChangeCheckbox: CheckboxProps["onChange"] = (e) => {
    setRememberMe(e.target.checked);
  };
  const onFinishRegister = (values: any) => {
    dispatch(
      registerUser({
        userName: values?.username,
        nickName: values?.username,
        email: values?.email,
        password: values?.password,
      })
    );
  };

  const onFinishFailedRegister = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onFinishLogin = (values: any) => {
    if (values?.email && values?.password) {
      dispatch(
        login({
          email: values?.email,
          password: values?.password,
          rememberMe: rememberMe,
          // role: null,
        })
      );
    }
  };

  const onFinishFailedLogin = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const __renderSignUpLogin = () => {
    return (
      <>
        <div
          onClick={() => setOpenSignUp(true)}
          className="cursor-pointer mr-[8px] h-[40px] max-500px:px-[0px] max-500px:h-[30px] max-500px:bg-inherit bg-[#F2F4F7] flex items-center rounded-[8px] px-[16px] max-500px:mr-[0px] max-500px:ml-[8px] text-[13px] max-500px:text-[12px] notosans_medium text-[#101828]"
        >
          {i18n.t("SIGN_UP")}
        </div>
        {width <= 500 && <div className="mx-[8px] h-[14px] w-px bg-[rgba(0,0,0,0.4)]"></div>}
        <div
          onClick={() => setOpenLogin(true)}
          className="cursor-pointer h-[40px] bg-[#EF6820] max-500px:text-[#101828] max-500px:px-[0px] max-500px:h-[30px] max-500px:bg-inherit flex items-center rounded-[8px] px-[16px] text-[13px] max-500px:text-[12px] notosans_medium text-[#FFFFFF]"
        >
          {i18n.t("LOGIN")}
        </div>
      </>
    );
  };
  const arrAcc = [
    { id: 1, name: "ag", img: vid1, title: "abbdc dhd", live: true },
    { id: 2, name: "ab", img: vid2, title: "abbdc dhd", live: false },
    { id: 3, name: "c", img: ava, title: "abbdc dhd", live: false },
    { id: 4, name: "dj", img: ava1, title: "abbdc dhd", live: true },
    { id: 5, name: "ef", img: ava2, title: "abbdc dhd", live: false },
    { id: 6, name: "fa", img: ava3, title: "abbdc dhd", live: false },
    { id: 7, name: "cg", img: ava4, title: "abbdc dhd", live: false },
    { id: 8, name: "hca", img: ava5, title: "abbdc dhd", live: false },
    { id: 1, name: "ag", img: vid1, title: "abbdc dhd", live: true },
    { id: 2, name: "ab", img: vid2, title: "abbdc dhd", live: false },
    { id: 3, name: "c", img: ava, title: "abbdc dhd", live: false },
    { id: 4, name: "dj", img: ava1, title: "abbdc dhd", live: true },
    { id: 5, name: "ef", img: ava2, title: "abbdc dhd", live: false },
    { id: 6, name: "fa", img: ava3, title: "abbdc dhd", live: false },
    { id: 7, name: "cg", img: ava4, title: "abbdc dhd", live: false },
    { id: 8, name: "hca", img: ava5, title: "abbdc dhd", live: false },
  ];
  const arrNew = arrAcc.filter((x) => x.name.includes(valueSearch));

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  }, []);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  useEffect(() => {
    if (token) {
      dispatch(getUserInfo({ token: token }));
    }
  }, [token]);

  useEffect(() => {
    if (showLoading === "register success") {
      setOpenSignUp(false);
      setOpenLogin(true);
    }
  }, [showLoading]);
  useEffect(() => {
    setToken(getToken());
    if (valueSearch !== "") {
    }
  }, []);

  return (
    <div
      className={`${
        pathName === "/livestream" && width <= 800
          ? "bg-none absolute shadow-none w-full"
          : "bg-[#FFFFFF] sm:shadow-[0px_1px_2px_0px_rgba(0,0,0,0.2)]"
      } h-[64px] sm:h-[46px] sm:px-[16px] px-[20px] flex items-center justify-between z-100`}
      style={{ zIndex: 100 }}
    >
      <div className={`${openSearch && "hidden"} flex items-center`}>
        <span
          className={`${
            pathName === "/livestream" && width <= 800 && "icon_live_color z-10"
          } mr-[15px] sm:mr-[12px ] cursor-pointer z-100`}
          onClick={() => setHideSidebar((prev) => !prev)}
        >
          {width > 800 ? cts.menuIcon : cts.menuRespon}
        </span>
        <img
          className={`${
            pathName === "/livestream" && width <= 800 && "hidden"
          } cursor-pointer md:h-[32px]`}
          onClick={() => navigate("/")}
          src={liveIcon}
        />
      </div>
      <Input
        type="text"
        className="w-[35%] max-800px:hidden h-[40px] rounded-[12px]"
        suffix={<span>{cts.searchIcon}</span>}
        placeholder={i18n.t("SEARCH")}
        autoComplete="off"
      />

      <div className={`${openSearch && "hidden"} flex items-center card_noti`}>
        {width <= 800 && (
          <Button
            onClick={() => setOpenSearch(true)}
            className={`${
              pathName === "/livestream" && "hidden"
            } btn-search-home border-none shadow-none p-0`}
          >
            {cts.searchIcon}
          </Button>
        )}
        {userInfo?._id && (
          <>
            <Link
              onClick={() => localStorage.removeItem("buyCoin")}
              to="/purchase-point"
              className={`${
                pathName === "/livestream" && width <= 800 && "hidden"
              } mr-[16px] cursor-pointer sm:mx-[12px] flex items-center sm:border-none h-[40px] border border-[#EF6820] rounded-[8px] max-800px:ml-[10px] px-[12px] sm:px-[0px]`}
            >
              <span className="mr-[8px] sm:mr-[0px] btn_icon">{cts.coinIcon1}</span>
              <span className="text-[#EF6820] sm:hidden text-[13px] notosans_medium">
                {i18n.t("PURCHASE_POINT")}
              </span>
            </Link>
            <div className="flex items-center bg-[#FEF6EE] rounded-[8px] h-[40px] px-[16px] mr-[16px]">
              <span className="text-[#344054] text-[16px] notosans_normal max-960px:hidden">
                {i18n.t("POINT")} :
              </span>
              <span className="ml-[8px] text-[#344054] text-[16px] notosans_bold mr-[2px]">
                {userInfo?.points || 0}
              </span>
              <span> {cts.coinIcon1}</span>
            </div>
            <Dropdown
              className={`${pathName === "/livestream" && width <= 800 && "hidden"} drd_info_res`}
              menu={{ items }}
              trigger={["click"]}
            >
              <a className="cursor-pointer" onClick={(e) => e.preventDefault()}>
                <img
                  className="rounded-[50%] h-[40px] w-[40px] sm:h-[24px] sm:w-[24px] object-cover"
                  alt="avatar"
                  src={userInfo?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${userInfo?.avatar}` : noAva}
                />
              </a>
            </Dropdown>
          </>
        )}
        {!userInfo?._id && __renderSignUpLogin()}
        <Modal
          centered
          className="modal_sign_up"
          open={openLogin}
          onOk={() => setOpenLogin(false)}
          onCancel={() => setOpenLogin(false)}
        >
          <img src={liveIcon} />
          <span className="text-[24px] max-500px:text-[20px] notosans_bold mt-[20px]">
            Welcome to Host Live
          </span>
          <span className="mt-[8px] text-[14px] notosans_normal">{i18n.t("W_U_BECOME")}</span>
          <Form
            name="login"
            className="w-full mt-[20px]"
            onFinish={onFinishLogin}
            onFinishFailed={onFinishFailedLogin}
            autoComplete="off"
          >
            <span className="text-[14px] notosans_bold">{i18n.t("EMAIL_ONLY")}</span>
            <Form.Item<FieldType>
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                type="email"
                className="h-[40px] rounded-[12px]"
                placeholder="例: user@mail.com"
              />
            </Form.Item>
            <span className="text-[14px] notosans_bold">{i18n.t("PASSWORD")}</span>
            <Form.Item<FieldType>
              name="password"
              className="hide_pass_sign"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input.Password
                className="h-[40px] rounded-[12px]"
                placeholder={i18n.t("PASSWORD")}
              />
              {/* <span
                onClick={() => {
                  setForgotPass(true);
                  setOpenLogin(false);
                }}
                className="text-[12px] cursor-pointer text-[#667085] notosans_medium flex justify-end"
              >
                {i18n.t("FORGOT")}
              </span> */}
            </Form.Item>
            <div className="flex items-center justify-between">
              <Checkbox onChange={onChangeCheckbox}>{i18n.t("REMEMBER_ME1")}</Checkbox>
              <span
                onClick={() => {
                  setForgotPass(true);
                  setOpenLogin(false);
                }}
                className="text-[12px] cursor-pointer text-[#667085] notosans_medium flex justify-end"
              >
                {i18n.t("FORGOT")}
              </span>
            </div>
            <Form.Item className="w-full">
              <Button
                className="bg-[#EF6820] mt-[20px] w-full hover:border-none hover:!text-white hover:bg-[#EF6820]/80 h-[40px] rounded-[8px] flex items-center justify-center text-[14px] notosans_bold text-white"
                htmlType="submit"
                // onClick={onLogin}
              >
                {i18n.t("LOGIN")}
              </Button>
            </Form.Item>
          </Form>
          <div className=" flex items-center w-full">
            <div className="bg-[#D0D5DD] h-[1px] w-full"></div>
            <span className="text-[#475467] mx-[20px] w-[22%] text-[14px] notosans_bold">
              {i18n.t("OR")}
            </span>

            <div className="bg-[#D0D5DD] h-[1px] w-full"></div>
          </div>
          <div className="mt-[30px] w-full text-[#344054] cursor-pointer text-[16px] notosans_bold h-[44px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D]">
            <span className="mr-[12px]">{cts.ggleIcon}</span>Google{i18n.t("REGISTER_W_ACC")}
          </div>
          <div className="mt-[12px] w-full text-[#344054] cursor-pointer text-[16px] notosans_bold h-[44px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D]">
            <span className="mr-[12px]">{cts.lineIcon}</span>LINE{i18n.t("REGISTER_W_ACC")}
          </div>
          {/* <div className="mt-[12px] w-full text-[#344054] cursor-pointer text-[16px] notosans_bold h-[44px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D]">
            <span className="mr-[12px]">{cts.twitterIcon}</span>Twitter{i18n.t("REGISTER_W_ACC")}
          </div>
          <span className="mt-[20px] flex items-center text-[12px] notosans_normal">
            {i18n.t("DONT_HAVE_ACC")}
            <span
              onClick={() => {
                setOpenSignUp(true);
                setOpenLogin(false);
              }}
              className="text-[12px] notosans_bold ml-[8px] cursor-pointer"
            >
              {i18n.t("REGISTRATION")}
            </span>
          </span> */}
        </Modal>
        <Modal
          centered
          className="modal_sign_up"
          open={openSignUp}
          onOk={() => setOpenSignUp(false)}
          onCancel={() => setOpenSignUp(false)}
        >
          <img src={liveIcon} />
          <span className="text-[24px] notosans_bold mt-[20px]">Welcome to Host Live</span>
          <span className="mt-[8px] text-[14px] notosans_normal">{i18n.t("W_U_BECOME")}</span>
          <Form
            name="basic"
            className="w-full mt-[20px]"
            onFinish={onFinishRegister}
            onFinishFailed={onFinishFailedRegister}
            autoComplete="off"
          >
            {/* <span className="text-[14px] notosans_bold flex items-center">
              {i18n.t("USER_NAME")}{" "}
            </span>
            <Form.Item<FieldType>
              name="username"
              rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input className="h-[40px] rounded-[12px]" placeholder={i18n.t("USER_NAME")} />
            </Form.Item> */}
            <span className="text-[14px] notosans_bold flex items-center">
              {i18n.t("NICK_NAME")}{" "}
              <span className="notosans_normal ml-[5px]">({i18n.t("CAN_BE_EDITED_LATER")})</span>
            </span>
            <Form.Item<FieldType>
              name="username"
              rules={[{ required: true, message: "Please input your nickname!" }]}
            >
              <Input className="h-[40px] rounded-[12px]" placeholder={i18n.t("NICK_NAME")} />
            </Form.Item>
            <span className="text-[14px] notosans_bold">{i18n.t("EMAIL_ONLY")}</span>
            <Form.Item<FieldType>
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                type="email"
                className="h-[40px] rounded-[12px]"
                placeholder="例: user@mail.com"
              />
            </Form.Item>
            <span className="text-[14px] notosans_bold">{i18n.t("PASSWORD")}</span>
            <Form.Item<FieldType>
              name="password"
              className="hide_pass_sign"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input.Password
                className="h-[40px] rounded-[12px]"
                placeholder={i18n.t("PASSWORD")}
              />
            </Form.Item>

            <Form.Item className="w-full">
              <Button
                className="bg-[rgb(239,104,32)] w-full hover:border-none hover:!text-white hover:bg-[#EF6820]/80 h-[40px] rounded-[8px] flex items-center justify-center text-[14px] notosans_bold text-white"
                htmlType="submit"
              >
                {i18n.t("CREATE_NEW_ACC")}
              </Button>
            </Form.Item>
          </Form>
          <div className=" flex items-center w-full">
            <div className="bg-[#D0D5DD] h-[1px] w-full"></div>
            <span className="text-[#475467] mx-[20px] w-[22%] text-[14px] notosans_bold">
              {i18n.t("OR")}
            </span>

            <div className="bg-[#D0D5DD] h-[1px] w-full"></div>
          </div>
          <div className="mt-[30px] w-full text-[#344054] cursor-pointer text-[16px] notosans_bold h-[44px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D]">
            <span className="mr-[12px]">{cts.ggleIcon}</span>Google{i18n.t("REGISTER_W_ACC")}
          </div>
          <div className="mt-[12px] w-full text-[#344054] cursor-pointer text-[16px] notosans_bold h-[44px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D]">
            <span className="mr-[12px]">{cts.lineIcon}</span>Line{i18n.t("REGISTER_W_ACC")}
          </div>
          {/* <div className="mt-[12px] w-full text-[#344054] cursor-pointer text-[16px] notosans_bold h-[44px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D]">
            <span className="mr-[12px]">{cts.twitterIcon}</span>Twitter{i18n.t("REGISTER_W_ACC")}
          </div> */}
          {/* <span className="mt-[20px] flex items-center text-[12px] notosans_normal">
            {i18n.t("ALREADY_A_USER")}
            <span
              onClick={() => {
                setOpenLogin(true);
                setOpenSignUp(false);
              }}
              className="text-[12px] notosans_bold ml-[8px] cursor-pointer"
            >
              {i18n.t("LOGIN")}
            </span>
          </span> */}
        </Modal>
        <Modal centered className="modal_sign_up" open={forgotPass}>
          <span className="text-[24px] notosans_bold text-[#111927]">{i18n.t("RESET_PASS")}</span>
          <span className="mt-[20px] text-[14px] notosans_normal">{i18n.t("WE_WILL")}</span>
          <span className="text-[14px] notosans_normal">{i18n.t("WE_WILL1")}</span>
          <Form
            name="basic"
            className="w-full mt-[20px]"
            // onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <span className="text-[14px] notosans_bold">{i18n.t("EMAIL_ONLY")}</span>
            <Form.Item<FieldType>
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input className="h-[40px] rounded-[12px]" placeholder="例: user@mail.com" />
            </Form.Item>
            <Form.Item className="w-full">
              <Button
                onClick={() => {
                  setResetPass(true);
                  setForgotPass(false);
                }}
                className="bg-[#EF6820] w-full hover:border-none hover:!text-white hover:bg-[#EF6820]/80 h-[40px] rounded-[8px] flex items-center justify-center text-[14px] notosans_bold text-white"
                htmlType="submit"
              >
                {i18n.t("SEND")}
              </Button>
            </Form.Item>
          </Form>

          <span
            onClick={() => {
              setOpenLogin(true);
              setForgotPass(false);
            }}
            className="mt-[20px] flex items-center text-[12px] notosans_normal"
          >
            {cts.returnIcon}
            <span className="text-[14px] text-[#475467] notosans_bold ml-[8px] cursor-pointer">
              {i18n.t("RETURN_LOGIN")}
            </span>
          </span>
        </Modal>
        <Modal centered className="modal_sign_up" open={resetPass}>
          <span className="text-[24px] notosans_bold text-[#111927]">{i18n.t("RESET_PASS")}</span>
          <span className="mt-[20px] text-[15px] text-[#101828] notosans_bold ">
            user@gmail.com<span className="notosans_normal">{i18n.t("RESET_UR_PASS_TO")}</span>
          </span>
          <span className="mt-[20px] text-[15px] text-[#475467] notosans_normal">
            {i18n.t("IF_U_DONT_RECEIVE")}
            <span className="notosans_bold text-[#EF6820]">{i18n.t("CLICK_TO_SEND")}</span>
          </span>
          <span
            onClick={() => {
              setOpenLogin(true);
              setResetPass(false);
            }}
            className="mt-[20px] flex items-center text-[12px] notosans_normal"
          >
            {cts.returnIcon}
            <span className="text-[14px] text-[#475467] notosans_bold ml-[8px] cursor-pointer">
              {i18n.t("RETURN_LOGIN")}
            </span>
          </span>
        </Modal>
        {/* ================================ */}
        {/* <Popconfirm
          placement="bottomRight"
          title={''}
          open={openPop}
          description={
            <div
              onBlur={(e) => {
                console.log('e', e);
                e.stopPropagation();
                setOpenPop(false);
              }}
              onClick={() => setOpenPop(false)}
              className="flex flex-col w-[250px]"
            >
              <div className="flex pb-[8px] items-center border-[#EAECF0] border-b mx-[16px] mt-[12px]">
                <img className="rounded-[20px] h-[40px] w-[40px]" alt="avatar" src={Avatar} />
                <div className="flex flex-col ml-[12px]">
                  <span className="text-[14px] text-[#344054] notosans_bold cursor-pointer">
                    HIKARI
                  </span>
                  <span className="text-[14px] text-[#475467] notosans_normal cursor-pointer">
                    hikari2023@gmail.com
                  </span>
                </div>
              </div>
              <div className="flex flex-col pb-[8px] pt-[8px] border-[#EAECF0] border-b mx-[16px]">
                <div className="flex items-center">
                  <span>{cts.userAva}</span>
                  <span className="text-[14px] notosans_normal cursor-pointer text-[#101828]">
                    {i18n.t('MY_ACCOUNT')}{' '}
                  </span>
                </div>
                <div className="flex items-center ">
                  <span>{cts.headIcon}</span>
                  <span className="text-[14px] notosans_normal cursor-pointer text-[#101828]">
                    {i18n.t('HELP')}{' '}
                  </span>
                </div>
              </div>
              <div className="flex pt-[8px] items-center pb-[12px] mx-[16px]">
                <span>{cts.logOutIcon}</span>
                <span className="text-[14px] notosans_normal cursor-pointer text-[#101828]">
                  {i18n.t('LOG_OUT')}{' '}
                </span>
              </div>
            </div>
          }
          className="pop_acc"
        >
          <a onClick={() => setOpenPop((prev) => !prev)}>
            <img className="rounded-[20px] h-[40px] w-[40px]" alt="avatar" src={Avatar} />
          </a>
        </Popconfirm> */}
      </div>
      {openSearch && (
        <>
          <div className="flex items-center w-full">
            <Button
              onClick={() => {
                setOpenSearch(false);
                setValueSearch("");
              }}
              className="border-none shadow-none p-0"
            >
              {cts.backIcon}
            </Button>
            <Input
              onChange={(e) => setValueSearch(e.target.value)}
              className="flex flex-1 ml-[12px] h-[38px] rounded-[8px]"
              suffix={<Button className="border-none shadow-none p-0">{cts.searchIcon}</Button>}
              placeholder={i18n.t("SEARCH")}
            />
          </div>
          <div
            className={`${
              valueSearch === "" && "hidden"
            } bg-white absolute mt-[250px] card_search_home z-10 h-[200px] shadow-[0px_0px_2px_1px_rgba(0,0,0,0.4)] rounded-[8px] overflow-auto w-[92%]`}
          >
            {arrNew.map((x) => {
              return (
                <div className="h-[50px] border-b border-rgba[(0,0,0,0.3)] flex items-center justify-between px-[16px]">
                  <div className="flex items-center">
                    <div
                      className={`${
                        x.live === true && "border-[1.5px] border-[#F04438]"
                      } h-[35px] w-[35px] rounded-[50%] mr-[10px]`}
                    >
                      <img src={x.img} className="h-[32px] w-[32px] rounded-[50%] object-cover" />
                    </div>
                    <span className="notosans_normal text-[14px] text-[#101828]">{x.name}</span>
                  </div>
                  <span className={`${x.live !== true && "hidden"} text-[#F04438] text-[12px]`}>
                    live
                  </span>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
export default HeaderComponent;
