import { Input, Popconfirm, Select } from "antd";
import React, { useEffect, useState } from "react";
import * as cts from "../../constants/ui/svg";
import liveIcon from "../../assets/images/liveIcon.png";
import noAva from "../../assets/images/noAva.png";
import Avatar from "../../assets/images/acc1.png";
import i18n from "locales/i18n";
import type { DrawerProps, RadioChangeEvent } from "antd";
import { Button, Drawer, Radio, Space } from "antd";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getFollow } from "slices/followSlice";
import { URL_UPLOAD_IMAGE } from "../../constants";
interface Props {
  setHideSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  hideSidebar: boolean;
}
const SidebarComponent: React.FC<Props> = ({ setHideSidebar, hideSidebar }) => {
  const dispatch = useAppDispatch();
  const [showMore, setShowMore] = useState(false);
  const [placement, setPlacement] = useState<DrawerProps["placement"]>("left");
  const navigate = useNavigate();
  const userInfo = useAppSelector((state) => state.users.userInfo);
  const channelsSt = useAppSelector((state) => state.channels.channels);
  const listFollow = useAppSelector((state) => state.follow.listFollow);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const arrMenu = [
    { link: "/", icon: cts.homeIcon, name: i18n.t("HOME") },
    { link: "/pickup", icon: cts.hightIcon, name: i18n.t("PICK_UP") },
    {
      link: "/delivery",
      icon: cts.playIcon,
      name: i18n.t("DURING_DELIVERY"),
      class: "justify-between",
    },
    { link: "/region", icon: cts.locationIcon, name: i18n.t("REGION") },
    { link: "/rank", icon: cts.rankIcon, name: i18n.t("RANKING") },
    // { link: "/", icon: cts.searchSpecialIcon, name: i18n.t("STORE_INFO") },
  ];
  // useEffect(() => {
  //   if (userInfo._id) {
  //     dispatch(getFollow({ follower: userInfo._id, type: "get_all" }));
  //   }
  // }, [userInfo._id]);
  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  }, []);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <Drawer
      title={
        <div className="h-[64px] max-500px:h-[46px] max-500px:pl-[0px] pl-[20px] bg-[#FFFFFF] flex items-center">
          <div className="flex items-center max-500px:justify-between max-500px:px-[16px] max-500px:w-full max-500px:flex-row-reverse">
            <span
              className="mr-[15px] max-500px:mr-[0px] cursor-pointer"
              onClick={() => setHideSidebar((prev) => !prev)}
            >
              {width > 500 ? cts.closeIcon1 : cts.closeRespon}
            </span>
            <img className="max-500px:h-[32px]" src={liveIcon} />
          </div>
        </div>
      }
      placement={placement}
      closable={false}
      onClose={() => setHideSidebar(false)}
      open={hideSidebar}
      key={placement}
    >
      <div className="max-500px:pt-[10px] max-500px:px-[16px] pt-[16px] px-[20px] flex-col flex bg-[#F9FAFB] w-[256px]">
        <span className="text-[#101828] notosans_bold text-[18px] mb-[8px]">
          {i18n.t("RECOMMENT_FOR_U")}
        </span>
        {arrMenu.map((x, y) => {
          return (
            <Link
              key={y}
              onClick={() => {
                setHideSidebar(false);
                window.scrollTo(0, 0);
              }}
              to={x.link}
              className={`${x.class} flex items-center cursor-pointer hover:bg-[#EAECF0] hover:rounded-[8px]`}
            >
              <div className="flex items-center">
                <span>{x.icon}</span>
                <span className="text-[#101828] notosans_normal ml-[9px]">{x.name}</span>
              </div>
              {y === 2 && (
                <div className="h-[18px] mr-[10px] flex items-center rounded-[4px] bg-[#F04438] text-white text-[10px] notosans_medium px-[8px]">
                  {channelsSt.length}
                </div>
              )}
            </Link>
          );
        })}
        <div className="border-[#EAECF0] border-b mt-[8px]"></div>
        <span
          className={`${
            userInfo?._id && listFollow?.length > 0 ? "" : "hidden"
          } my-[8px] text-[#101828] text-[14px] notosans_bold`}
        >
          {i18n.t("CHANNEL_U_FOLLOW")} ({listFollow?.length})
        </span>
        <div className={`${!userInfo?._id && "hidden"} flex flex-col pb-[80px]`}>
          {listFollow?.map((x: any, y: number) => {
            return (
              <div
                key={y}
                onClick={() => {
                  window.scrollTo(0, 0);
                  if (
                    channelsSt?.find((i: any) => i?.user?._id.includes(x?.streamer?._id))?.user?._id
                  ) {
                    navigate({
                      pathname: "/livestream",
                      search: createSearchParams({
                        key: `${x?.streamer?._id}`,
                      }).toString(),
                    });
                  } else {
                    navigate({
                      pathname: "/streamer-profile",
                      search: createSearchParams({
                        key: `${x?.streamer?._id}`,
                      }).toString(),
                    });
                  }
                  setHideSidebar(false);
                }}
                className={`${
                  y > 4 && !showMore && "hidden"
                } flex items-center justify-between h-[40px] px-[8px] cursor-pointer`}
              >
                <div className="flex items-center">
                  <img
                    className={`${
                      !channelsSt?.find((i: any) => i?.user?._id.includes(x?.streamer?._id))?.user
                        ?._id && "grayscale"
                    } h-[24px] w-[24px] rounded-[12px] object-cover`}
                    src={
                      x?.streamer?.avatar
                        ? `${URL_UPLOAD_IMAGE}/avatars/${x?.streamer?.avatar}`
                        : noAva
                    }
                  />
                  <span
                    className={`${
                      !channelsSt?.find((i: any) => i?.user?._id.includes(x?.streamer?._id))?.user
                        ?._id
                        ? "text-[#98A2B3]"
                        : "text-[#101828]"
                    } notosans_normal ml-[12px] text-[12px]`}
                  >
                    {x?.streamer?.nickName}
                  </span>
                </div>
                {channelsSt?.find((i: any) => i?.user?._id.includes(x?.streamer?._id))?.user
                  ?._id && <span>{cts.activeLive}</span>}
              </div>
            );
          })}

          {showMore && (
            <div
              onClick={() => setShowMore((prev) => !prev)}
              className={`${listFollow?.length <= 5 && "hidden"} flex items-center cursor-pointer`}
            >
              <span>{cts.hideIcon}</span>
              <span className="text-[#101828] text-[12px] notosans_normal">
                {i18n.t("SHOW_LESS")}
              </span>
            </div>
          )}
          {!showMore && listFollow?.length > 5 && (
            <div
              onClick={() => setShowMore((prev) => !prev)}
              className={`${listFollow?.length <= 5 && "hidden"} flex items-center cursor-pointer`}
            >
              <span>{cts.showIcon}</span>
              <span className="text-[#101828] text-[12px] notosans_normal">
                {i18n.t("SHOW_MORE")}
              </span>
            </div>
          )}
          <div className="hidden max-800px:block items-center">
            <span className="mt-[12px] mr-[12px] mb-[8px] notosans_bold text-[#101828] text-[14px]">
              {i18n.t("LANGUAGE")}
            </span>
            <Select
              className="slc_language"
              defaultValue={i18n.t("JAPANESE")}
              style={{ width: 179, height: 38 }}
              options={[
                { value: "English", label: "English" },
                { value: i18n.t("JAPANESE"), label: i18n.t("JAPANESE") },
              ]}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};
export default SidebarComponent;
