import React, { useEffect, useState } from "react";
import * as cts from "../../../constants/ui/svg";
import i18n from "locales/i18n";
import BreadcrumbComponent from "components/Common/Breadcrumb";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import view1 from "../../../assets/images/view1.png";
import view2 from "../../../assets/images/view2.png";
import view3 from "../../../assets/images/view3.png";
import view4 from "../../../assets/images/view4.png";
import vid2 from "../../../assets/images/HOST IMAGE/pic03 (3).jpg";
import vid9 from "../../../assets/images/event.png";
import noAva from "../../../assets/images/noAva.png";
import noShop from "../../../assets/images/noShop.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getDetailShop } from "slices/shopSlice";
import ButtonFollowComponent from "components/Common/ButtonFollow";
import { URL_UPLOAD_IMAGE } from "../../../constants";
import ModalEventLive from "components/Common/ModalEventLive";
import { getFollow } from "slices/followSlice";
import ButtonFollowV1Component from "components/Common/ButtonFollowV1";

type Props = {};

const DetailStore = (props: Props) => {
  const [searchparams] = useSearchParams();
  const navigate = useNavigate();
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const dispatch = useAppDispatch();
  const [openModalEvent, setOpenModalEvent] = useState(false);
  const idStore = searchparams.get("key_store")?.toString() || "";
  const detailShop = useAppSelector((state) => state.shop.detailShop);
  // const followList = useAppSelector((state) => state.follow.listFollowInShop);
  const listHost = useAppSelector((state) => state.follow.listHost);
  const userInfo = useAppSelector((state) => state.users.userInfo);
  const channelsSt = useAppSelector((state) => state.channels.channels);
  const listFollowInShop = useAppSelector((state) => state.follow.listFollowInShop);
  const arrEvents = [{ img: vid9 }, { img: vid9 }, { img: vid9 }];
  useEffect(() => {
    if (idStore) {
      dispatch(getDetailShop(idStore as string));
    }
  }, [idStore]);
  // useEffect(() => {
  //   if (userInfo._id && listHost?.length == 0) {
  //     dispatch(getFollow({ follower: userInfo._id, type: "get_all" }));
  //   }
  // }, [userInfo._id]);
  const arrImg = [
    { view: view1 },
    { view: view2 },
    { view: view3 },
    { view: view4 },
    { view: view3 },
    { view: view4 },
    { view: view3 },
    { view: view4 },
  ];
  const countFollowersByStreamer = (userId: any) => {
    const result = listFollowInShop.filter((item: any) => item.streamer === userId);
    const totalFollowers = result.reduce(
      (count: any, item: any) => count + item.followers.length,
      0
    );
    return totalFollowers;
  };

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  }, []);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    if (idStore) {
      dispatch(getFollow({ shop: idStore }));
    }
  }, [idStore]);
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <div className="flex pt-[10px] pb-[40px] mt-[2px] detail_store justify-center max-500px:pt-3">
      <div className="flex flex-col w-full size_content_hd">
        <div className="">
          <BreadcrumbComponent breadMain={i18n.t("STORE_INFO")} breadChild={detailShop?.name} />
        </div>
        <div className="mt-[40px] border border-[#EAECF0] pl-[20px] rounded-[12px] bg-white flex items-center py-[20px] max-500px:flex-col max-500px:px-4 max-500px:mt-0">
          <div className="w-[35%] pr-[20px] max-500px:w-full max-500px:pb-4 max-500px:border-b border-[#EAECF0] max-500px:px-0">
            <div className="flex items-center justify-between">
              <span className="text-[18px] text-[#101828] notosans_bold max-500px:text-base">
                {detailShop?.name}
              </span>
              <img
                src={
                  detailShop?.avatar ? `${URL_UPLOAD_IMAGE}/shops/${detailShop?.avatar}` : noShop
                }
                className="h-[60px] w-[60px] rounded-[50%] object-cover"
              />
            </div>
            <div className="flex items-center mt-[8px]">
              <span className="h-[16px] w-[16px] mr-[8px]">{cts.location1Icon}</span>
              <span className="text-[#667085] text-[14px] notosans_normal">
                {detailShop?.address}
              </span>
            </div>
            {/* <span className="text-[#667085] text-[14px] notosans_normal ml-[24px]"> */}
            {/* 第3オーシャンビル3F */}
            {/* </span> */}
            <div className="flex items-center mt-[8px]">
              <span className="h-[16px] w-[16px] mr-[8px]">{cts.phoneIcon}</span>
              <span className="text-[#667085] text-[14px] notosans_normal">
                {detailShop?.phone}
              </span>
            </div>
            <div className="flex items-center mt-[8px]">
              <span className="h-[16px] w-[16px] mr-[8px]">{cts.memberIcon}</span>
              <span className="text-[#667085] text-[14px] notosans_normal">
                {detailShop?.userOfShops?.length} {i18n.t("LIVER")}
              </span>
            </div>
          </div>
          {/* <div className="h-full w-px px-[20px] bg-[#EAECF0]"></div> */}
          <div className="w-[65%] border-l border-[#EAECF0] pl-[20px] max-500px:w-full max-500px:border-none max-500px:px-0 max-500px:mt-4">
            <Swiper
              slidesPerView={width <= 800 ? 2 : width <= 1080 ? 2.5 : width <= 1400 ? 3.5 : 4.5}
              spaceBetween={10}
              loop={false}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="w-full swiper1 swiper_detail_store  max-500px:hidden"
            >
              {detailShop?.images?.map((x, y) => {
                return (
                  <SwiperSlide key={y}>
                    <img
                      className=" rounded-[8px] object-cover h-[160px] w-[160px] "
                      src={x ? x : vid2}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <Swiper
              slidesPerView={3.1}
              spaceBetween={10}
              loop={false}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="hidden w-full swiper1 store_swiper max-500px:flex"
            >
              {arrImg?.map((x, y) => {
                return (
                  <SwiperSlide key={y}>
                    <img
                      className=" rounded-[8px] object-cover h-[160px] w-[160px] "
                      src={x.view}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        <span className="text-[#101828] text-[24px] notosans_bold mt-[40px] max-500px:mt-5 max-500px:text-base">
          {i18n.t("LIVER_LIST")}
        </span>
        <div className="grid grid-cols-6 max-1400px:grid-cols-5 max-1080px:grid-cols-4 max-800px:!grid-cols-3 gap-4 mt-[20px] mb-[20px] max-500px:!grid-cols-2">
          {detailShop?.userOfShops?.map((x: any, y: any) => {
            return (
              <div
                key={y}
                className="flex flex-col cursor-pointer items-center justify-center my-[15px] max-500px:mt-0"
              >
                <div
                  onClick={() => {
                    window.scrollTo(0, 0);
                    if (channelsSt?.find((i: any) => i?.user?._id.includes(x?._id))?.user?._id) {
                      navigate({
                        pathname: "/livestream",
                        search: createSearchParams({
                          key: `${x?._id}`,
                        }).toString(),
                      });
                    } else {
                      navigate(
                        {
                          pathname: "/streamer-profile",
                          search: createSearchParams({
                            key: `${x?._id}`,
                          }).toString(),
                        },
                        {
                          state: {
                            beforePath: i18n.t("STORE_INFO"),
                            prevPath: detailShop?.name,
                          },
                        }
                      );
                    }
                  }}
                  className={`${
                    channelsSt?.find((i: any) => i?.user?._id.includes(x?._id))?.user?._id &&
                    "border-[4px] border-[#F04438] cursor-pointer relative flex items-end justify-center"
                  } w-[160px] h-[160px] rounded-[50%]`}
                >
                  {channelsSt?.find((i: any) => i?.user?._id.includes(x?._id))?.user?._id && (
                    <span className="icon_live_profile absolute">{cts.live1Icon}</span>
                  )}
                  <img
                    className="w-[160px] h-[160px] rounded-[50%] object-cover"
                    src={
                      !x.avatar || x.avatar === "null"
                        ? noAva
                        : `${URL_UPLOAD_IMAGE}/avatars/${x?.avatar}`
                    }
                  />
                </div>
                <span
                  onClick={() => {
                    window.scrollTo(0, 0);
                    if (channelsSt?.find((i: any) => i?.user?._id.includes(x?._id))?.user?._id) {
                      navigate({
                        pathname: "/livestream",
                        search: createSearchParams({
                          key: `${x?._id}`,
                        }).toString(),
                      });
                    } else {
                      navigate(
                        {
                          pathname: "/streamer-profile",
                          search: createSearchParams({
                            key: `${x?._id}`,
                          }).toString(),
                        },
                        {
                          state: {
                            beforePath: i18n.t("STORE_INFO"),
                            prevPath: detailShop?.name,
                          },
                        }
                      );
                    }
                  }}
                  className="text-[#0C111D] cursor-pointer text_1_line text-[16px] notosans_normal mt-[8px] max-500px:text-sm"
                >
                  {x?.nickName}
                </span>
                <span className="text-[#667085] mb-[8px] text-[12px] notosans_normal mt-[4px]">
                  {/* 20.4k */}
                  {countFollowersByStreamer(x?._id)}
                  {i18n.t("FOLLOWER")}
                </span>
                {/* <ButtonFollowComponent idStreamer={x?._id} /> */}
                <ButtonFollowV1Component idStreamer={x?._id} />
              </div>
            );
          })}
        </div>
        {/* <span className="text-[#101828] text-[24px] mt-[40px] notosans_bold">
          {i18n.t("EVENT")}
        </span>
        <div className=" my-[20px] grid grid-cols-3 max-1080px:grid-cols-2 gap-4 max-500px:grid-cols-1">
          {arrEvents.map((x, y) => {
            return (
              <div
                onClick={() => setOpenModalEvent(true)}
                key={y}
                className="flex flex-col cursor-pointer"
              >
                <img className=" object-cover rounded-t-[8px]" src={x.img} />
                <div className="bg-white p-[16px] flex flex-col rounded-b-[8px]">
                  <div className="bg-[#EF6820] rounded-[8px] h-[24px] flex items-center px-[8px] w-fit">
                    <span className="text-white text-[12px] notosans_medium ">
                      2024年7月7日 ー 21:00~
                    </span>
                  </div>
                  <span className="text-[#1D2939] mt-[12px] text-[16px] notosans_medium text_2_line">
                    イベントのタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。
                  </span>
                </div>
              </div>
            );
          })}
        </div> */}
      </div>
      <ModalEventLive open={openModalEvent} setOpen={setOpenModalEvent} />
    </div>
  );
};

export default DetailStore;
