import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import CryptoJS from "crypto-js";
import ModalDeleteSuccess from "features/myPage/component/modalDeleteSuccess";
import Cookies from "js-cookie";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  ChangePasswordParams,
  RegisterUserProps,
  UserProps,
  webHistoryPointUserParams,
} from "types/user";
import {
  changePasswordAPI,
  deleteAccountAPI,
  deleteUserAvatarAPI,
  getProfileAPI,
  loginAPI,
  registerUserAPI,
  supportContactAPI,
  updateUserAPI,
  webHistoryPointUserAPI,
} from "../apis";
import { ENCODE_SECRET_KEY } from "../constants";
import { addComponent, errorToast, successToast, toast } from "../helpers";
import i18n from "../locales/i18n";
import { showLoading } from "../slices/commonSlice";
import * as act from "../slices/userSlice";

function* loginSaga(action: PayloadAction<any>) {
  const { email, password, rememberMe, role } = action.payload;
  yield put(showLoading("login"));

  try {
    const res: AxiosResponse<any> = yield call(loginAPI, {
      email: email.includes("@") ? email : email + "@gmail.com",
      password,
      rememberMe,
      role,
    });

    if (res.data?.message === "Unauthorized") {
      toast(i18n.t("WRONG_USER_OR_PASSWORD"), "danger");
      yield put(showLoading(false));
    } else if (res.data.access_token) {
      successToast("ログインできました");
      yield put(act.loginSuccess(res.data));

      Cookies.set(
        "token_webnomi",
        CryptoJS.AES.encrypt(res.data.access_token, ENCODE_SECRET_KEY).toString(),
        {
          expires: action.payload.remember_me ? 365 : 7,
        }
      );

      yield put(showLoading(false));

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  } catch (error: any) {
    console.log("error", error);
    errorToast(i18n.t("LOGIN_FAILED"));
    yield put(showLoading(""));
  }
}

// function* getUserInfoSaga({ payload }: any) {
//   try {
//     const res:AxiosResponse = yield call(getUserInfoAPI, payload.token);
//     yield put(act.getUserInfoSuccess(res.data));
//   } catch (error:any) {
//     errorToast(error, error.message);
//   }
// }
function* registerUserSaga(action: PayloadAction<RegisterUserProps>) {
  const { nickName, email, password, userName } = action.payload;
  yield put(showLoading("register"));

  try {
    const res: AxiosResponse<any> = yield call(registerUserAPI, {
      userName,
      nickName,
      email: email.includes("@") ? email : email + "@gmail.com",
      password,
    });

    if (res.data?._message === "User validation failed") {
      toast(i18n.t("EMAIL_ALREADY_EXISTS"), "danger");
      yield put(showLoading(false));
    } else {
      successToast(i18n.t("REGISTER_USER_SUCCESS"));
      yield put(act.registerUserSuccess(res.data));
      yield put(showLoading("register success"));
    }
  } catch (error: any) {
    console.log("error", error);
    errorToast(i18n.t("REGISTER_FAILED"));
    yield put(showLoading(""));
  }
}
function* updateUserSaga(action: PayloadAction<{ token: string; id: string; formData: any }>) {
  const { token, id, formData } = action.payload;
  const formDataFile = new FormData();
  for (let key in formData) {
    if (formData[key]) {
      formDataFile.append(key, formData[key]);
    }
  }
  yield put(showLoading("update"));
  try {
    const res: AxiosResponse<UserProps> = yield call(updateUserAPI, token, id, formDataFile);
    if (res.data) {
      successToast(i18n.t("UPDATED"));
      yield put(act.updateUserSuccess(res.data));
      yield put(showLoading("update success"));
    } else {
      yield put(showLoading(false));
    }
  } catch (error: any) {
    errorToast(error.response.data.message);
    yield put(showLoading(""));
  } finally {
    setTimeout(() => {
      localStorage.removeItem("a");
    }, 10000);
  }
}
function* getUserInfoSaga({ payload }: any) {
  try {
    const res: AxiosResponse = yield call(getProfileAPI, payload.token);
    yield put(act.getUserInfoSuccess(res.data));
  } catch (error: any) {
    Cookies.remove("token_webnomi");
    errorToast(error.message);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }
}
function* changePasswordSaga(action: PayloadAction<{ token: string; data: ChangePasswordParams }>) {
  const { token, data } = action.payload;
  try {
    const res: AxiosResponse = yield call(changePasswordAPI, token, data);
    if (res.data.error) {
      errorToast(res.data.error);
      yield put(showLoading(false));
    } else {
      yield put(act.changePasswordSuccess(res.data));
      successToast(i18n.t("UPDATED"));
      Cookies.remove("token_webnomi");
      Cookies.set("open_login", "open_login");
      window.location.href = "/";
    }
  } catch (error: any) {
    errorToast(error.message);
    yield put(showLoading(""));
  }
}

function* deleteAccountSaga(action: PayloadAction<{ token: string; password: string }>) {
  const { token, password } = action.payload;
  try {
    const res: AxiosResponse = yield call(deleteAccountAPI, token, password);
    if (res.status !== 200 && res.status !== 201) {
      errorToast(i18n.t("PASSWORD_INCORRECT"));
      yield put(act.deleteAccountError());
    } else {
      yield put(act.deleteAccountSuccess());
      addComponent(ModalDeleteSuccess, {
        backHome: () => {
          window.location.reload();
        },
      });
    }
  } catch (error) {
    errorToast(i18n.t("PASSWORD_INCORRECT"));
  }
}

function* deleteUserAvatarSaga(action: PayloadAction<{token: string, id: string, data: Object}> ) {
  const { token, id, data} = action.payload;
  try {
    const res: AxiosResponse<UserProps> = yield call(deleteUserAvatarAPI, token, id, data);
    if(res.status === 200 || res.status === 201){
      yield put(act.deleteUserAvatarSuccess());
      successToast(i18n.t("DELETE_AVATAR_SUCCESS"));
    }
  } catch (error) {
    errorToast(i18n.t("DELETE_AVATAR_ERROR"));
  }
}
//Ngoc Na
function* supportContactSaga(action: PayloadAction<{ data: any }>) {
  const { data } = action.payload;
  yield put(showLoading("support_contact"));
  try {
    const res: AxiosResponse = yield call(supportContactAPI, data);
    if (res.status === 201 || res.status === 200) {
      successToast(i18n.t("SEND_SUPPORT_SUCCESS"));
    }
    yield put(showLoading("send_success"));
  } catch (error: any) {
    errorToast(i18n.t("SEND_SUPPORT_FAILURE"));
    yield put(showLoading(""));
  }
}
function* getWebHistoryPointUserSaga(action: PayloadAction<{ params: webHistoryPointUserParams }>) {
  try {
    const res: AxiosResponse = yield call(webHistoryPointUserAPI, action.payload.params);
    if (res.status === 200 || res.status === 201) {
      yield put(act.getWebHistoryUserPointSuccess(res.data));
    }
  } catch (error: any) {
    console.log(error.message);
  }
}
export default function* userWatcher() {
  yield takeLatest(act.login.type, loginSaga);
  yield takeEvery(act.getUserInfo.type, getUserInfoSaga);
  yield takeEvery(act.registerUser.type, registerUserSaga);
  yield takeEvery(act.updateUser.type, updateUserSaga);
  yield takeLatest(act.changePassword.type, changePasswordSaga);
  yield takeLatest(act.deleteAccount.type, deleteAccountSaga);
  yield takeLatest(act.supportContact.type, supportContactSaga);
  yield takeLatest(act.deleteUserAvatar.type, deleteUserAvatarSaga);
  yield takeLatest(act.getWebHistoryUserPoint.type, getWebHistoryPointUserSaga);
  // yield takeLatest(act.logout.type, logoutSaga);
}
